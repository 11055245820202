export const getCookie = name => {
  function escape(s) {
    return s.replace(/\/([.*+?/\\^${}()|/\\[\]/\\/\\])/g, '\\$1');
  }
  const match = document.cookie.match(
    RegExp(`(?:^|;\\s*)${escape(name)}=([^;]*)`)
  );
  return match ? match[1] : null;
};

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};
