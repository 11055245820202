const step = () => ({
  step: 1
});

const formDataCotizar = () => ({
  form1: {},
  form2: {},
  form3: {}
});

const expire = () => ({
  expire: ''
});

export const initialState = () => ({
  step: step(),
  formDataCotizar: formDataCotizar(),
  expire: expire()
});
