import React, { useState, useEffect } from 'react';

import { getCookie, setCookie } from '../../utils/cookies';
import {
  colombiaComplement,
  prefixAr,
  prefixCo,
  prefixMx,
  prefixUs,
  langAr,
  langCo,
  langMx,
  langUs
} from '../../location/urls';

import { config } from '../../config';

export const LocationContext = React.createContext({
  idiom: '',
  idiomAPI: '',
  country: ''
});

const initialLocalion = (_setCountry, _setIdiom, _setIdiomAPI) => {
  let country = getCookie('country');
  let idiom = getCookie('idiom');
  const domainName = document.location.hostname;
  const pathName = document.location.pathname;

  if (!country || !idiom) {
    switch (domainName) {
      case config.REACT_APP_URL_AR:
        country = prefixAr;
        idiom = langAr;
        break;
      default:
        country = prefixAr;
        idiom = langAr;
        break;
    }
  }
  setCookie('country', country);
  setCookie('idiom', idiom);
  _setCountry(country);
  _setIdiom(idiom);
  _setIdiomAPI(idiom.replace('_', '-'));
};

const Location = (props) => {
  const [idiom, _setIdiom] = useState('');
  const [country, _setCountry] = useState('');
  const [idiomAPI, _setIdiomAPI] = useState('');

  useEffect(() => {
    initialLocalion(_setCountry, _setIdiom, _setIdiomAPI);
  }, []); //eslint-disable-line

  return (
    country &&
    idiom && (
      <LocationContext.Provider
        value={{
          country,
          idiom,
          idiomAPI
        }}
      >
        {props.children}
      </LocationContext.Provider>
    )
  );
};

export default Location;
