import React, { useState, createContext } from 'react';
import useRefCallback from '../CustomHooks/useRefCallback';

export const HtmlContext = createContext({
  state: {
    refUpgradeBanner: {}
  },
  utils: {
    getUpgradeBanner: () => {}
  },
  actions: {
    refCallbackUpgradeBanner: () => {}
  }
});

const HtmlContextParent = props => {
  const [refUpgradeBanner, refCallbackUpgradeBanner] = useRefCallback();
  const [state, setState] = useState({ refUpgradeBanner });

  const getUpgradeBanner = () => {
    return refUpgradeBanner && refUpgradeBanner.current;
  };

  const value = {
    state,
    utils: {
      getUpgradeBanner
    },
    actions: {
      refCallbackUpgradeBanner
    }
  };

  return (
    <HtmlContext.Provider value={value}>{props.children}</HtmlContext.Provider>
  );
};

export default HtmlContextParent;
