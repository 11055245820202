import React, { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { LocationContext } from '../Location/Location';

const initializeGtm = country => {
  const isAr = country === 'ar';
  if (isAr) {
    const GTM = process.env.REACT_APP_GTM_AR;
    const tagManagerArgs = {
      gtmId: GTM,
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);
  }
};

const GTM = () => {
  const { country } = useContext(LocationContext);

  useEffect(() => {
    initializeGtm(country);
  }, []);

  return <></>;
};

export default GTM;
