import React, { useEffect } from 'react';
import { Route as ReactRoute } from 'react-router-dom';

const documentTitleByPath = () => {
  const path = window.location.pathname;
  const pieces = path.split('/');
  const lastPiece = pieces[pieces.length - 1];
  const lastPieceFormated = lastPiece.replace(/-/gi, ' ');

  document.title = `${lastPieceFormated} | AXA`;
};

const Route = ({ key, path, component }) => {
  useEffect(() => {
    documentTitleByPath();
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path]);

  return <ReactRoute key={key} path={path} component={component} />;
};

export default Route;
