import React, { useCallback, useState } from 'react';

const useRefCallback = () => {
  const [ref, setRef] = useState({});

  const refCallback = useCallback(node => {
    if (node) {
      setRef({ current: node });
    }
  }, []);

  return [ref, refCallback];
};

export default useRefCallback;
