import { langAr} from '../location/urls';

export const ISOAr = 'ARS';

export const getCurrencyByFormType = formType => {
  let currencyValue;

  switch (formType) {
    case langAr:
      currencyValue = ISOAr;
      break;
    default:
      currencyValue = ISOAr;
      break;
  }
  return currencyValue;
};

export const getAmountFormat = amount => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};
